import { createRouter, createWebHistory } from "vue-router";

function lazyLoad(view) {
      return () => import(`../views/${view}.vue`)
}

const routes = [
      {
            path: "/:catchAll(.*)",
            name: "Error404",
            meta: { layout: "default", title: "Page introuvable" },
            component: lazyLoad('Errors/404'),
      },
      {
            path: '/',
            redirect: '/base',
      },
      // {
      //       path: '/base',
      //       name: 'homePage',
      //       component: lazyLoad('Home'),
      //       meta: { layout: "default", title: "Home" , requiresAuth: true },
      //       props: true,
      // },
      {
            path: '/neighbourhood',
            name: 'Neighbourhood-page',
            component: lazyLoad('NeighbourhoodComponent/Neighbourhood-page'),
            meta: { layout: "default", title: "Neighbourhood" , requiresAuth: true },
            props: true,
      },
      {
            path: '/units',
            name: 'units',
            component: lazyLoad('UnitsComponent/UnitsPage'),
            meta: { layout: "default", title: "Units" , requiresAuth: true },
            props: true,
      },
      {
            path: '/units/compare',
            name: 'unitsCompare',
            component: lazyLoad('UnitsComponent/Compare'),
            meta: { layout: "default", title: "UnitsCompare" , requiresAuth: true },
            props: true,
      },
      {
            path: '/demo',
            name: 'demo-page',
            component: lazyLoad('demo-page'),
            meta: { layout: "default", title: "DEMO" , requiresAuth: false },
            props: true,
      },
      {
            path: '/three',
            name: 'Three',
            component: lazyLoad('Three'),
            meta: { layout: "default", title: "Three" , requiresAuth: true },
      },
      {
            path: '/worksheet-page',
            name: 'worksheet-page',
            component: lazyLoad('Form/worksheet-page'),
            meta: { layout: "default", title: "WORKSHEET" , requiresAuth: true },
      },
      {
            path: '/base',
            name: 'floorplans-page',
            component: lazyLoad('floorplans-page'),
            meta: { layout: "default", title: "FloorPlans" , requiresAuth: true },
            children: [
                  { 
                        path: '/floorPlans-details-page/:id',
                        name: 'floorPlans-details-page', 
                        component: lazyLoad('floorplans-page'), 
                        meta: { layout: "default", title: "floorPlans Details" , requiresAuth: true }
                  },
            ],
      },
      {
            path: '/floorPlans-details-page/:id',
            name: 'floorPlans-details-page',
            component: lazyLoad('floorPlans-details-page'),
            meta: { layout: "default", title: "floorPlans Details" , requiresAuth: true },
            
      },
      {
            path: '/decoreSelection-page',
            name: 'decoreSelection-page',
            component: lazyLoad('decoreSelection-page'),
            meta: { layout: "default", title: "Decore Selection" , requiresAuth: true },
      },
      {
            path: '/compare-page',
            name: 'compare-page',
            component: lazyLoad('compare-page'),
            meta: { layout: "default", title: "Compare" , requiresAuth: true },
      },
      {
            path: '/bookmark',
            name: 'bookmark-page',
            component: lazyLoad('bookmarksList/bookmarksList'),
            meta: { layout: "default", title: "bookmark" , requiresAuth: true },
      }
];

const router = new createRouter({
      history: createWebHistory(),
      routes,
      linkActiveClass: 'active-link',
      linkExactActiveClass: 'exact-active-link',
});

export default router;