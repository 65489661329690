import App from './App.vue';
import router from "@/router";
import { createApp } from 'vue';
import mitt from 'mitt';
import store from "@/store";
import vueVimeoPlayer from 'vue-vimeo-player';

import '@/assets/main.css'
// import '../node_modules/tw-elements/dist/js/index.min.js';
import axios from "axios";

axios.defaults.headers = {
    'project' : 'littleLake',
    'X-CLIENT-ID': 5,
    'X-CLIENT-SECRET': 'YGhi8w7nEFN277mSKTLVxUwsar6V5MY1v5Hs9J1Y'
}
const app = createApp(App);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;
app.config.productionTip = false;

app.use(vueVimeoPlayer);
app.use(router);
app.use(store);
app.mount('#app');
