<template>
  <div class="qoo-main h-full flex flex-col min-h-screen">

    <Header class="shrink-0 grow-0" />

    <div class="grow hhhhh">
      <router-view></router-view>
    </div>
    

    <OverlayLoader v-show="isLoading"/>
  </div>
</template>

<script>

import OverlayLoader from "@/components/base/overlay/OverlayLoader.vue";
import Header from "@/components/common/header/Header.vue"


export default {
  name: 'App',
  components: { OverlayLoader , Header },

  computed: {
    isLoading: {
      get(){
        return this.$store.state.isLoading;
      }
    },
  },

}

</script>
<style scoped>
.h { height: calc(100vh + 450px); }
</style>
