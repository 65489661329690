<template>

    <header>
        <div  class="header p-3">
            <router-link to="/">
                <img src="@/assets/images/prImg/logo1.svg" class="h-12"/>
            </router-link>
            
            <nav class="header__nav-wrap" :class="{ 'open': burgerVisibility }">

                <img src="@/assets/images/prImg/logo2.svg" class="h-7"/>
            </nav>
        </div>
        
    </header>

</template>

<script>

    export default {
        name: 'qoo-header',
        setup(){
        },
        data() {
            return {
                pageTitle: '' ,
                burgerVisibility: false
            }
        },

        watch: {
            '$route' (to) {
                this.pageTitle = to.meta.title;
            }
        },

        methods: {
            changeVisibility() {
                this.burgerVisibility = !this.burgerVisibility;
            }
        },

        mounted() {
            this.pageTitle = this.$router.currentRoute._value.meta.title;
        }
    }
</script>

<style lang='scss'>
    @import 'Header';
</style>